.add-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    max-width: 400px;
    margin: 0 auto;
}

.add-card-form {
    width: 80%;
    margin: auto;
}

.add-card-form input {
    width: 100%;
    height: 43px;
    box-sizing: border-box;
    padding-left: 1.5rem;
    margin-bottom: 20px;
}

.add-card-form .add {
    width: 100%;
    height: 43px;
    border-radius: 25px;
    background-color: black;
    color: white;
}

.add-card-form .back {
    width: 100%;
    height: 43px;
    border-radius: 25px;
    background-color: white;
    color: black;
}