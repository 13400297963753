.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 400px;
    margin: 0 auto;
}

.login-form {
    width: 80%;
    margin: auto;
}


.input-icon {
    height: 24px;
    width: 24px;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 2px;
    transform: translateY(-90%);
}

.input-group {
    position: relative;
    width: 100%;
    margin: auto;
}

.input-group input {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding-left: 1.5rem;
    margin-bottom: 20px;
}

.login-button {
    margin-top: 50px;
    width: 100%;
    height: 43px;
    border-radius: 25px;
    background-color: black;
    color: white;
}

.login-form .heading {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}

.login-form .sub-heading {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 40px;
}

.submit-group p {
    font-size: 14px;
    margin-bottom: 0;
}

.submit-group p span {
    font-weight: 700;
    text-decoration: underline;
}