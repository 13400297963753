.profile {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    height: 100vh;
    background-color: #f5f5f5;
}

.top {
    height: calc(100%/3);
    margin-right: 40px;
}

.top .data {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.middle {
    height: calc(100%/3);
}

.bottom {
    padding-bottom: -10%;
    height: calc(100%/3);
}

.bottom .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

a .icon,
p .icon {
    height: 23px;
    transform: translateY(20%);
    color: black;
}

.data p {
    margin: 0;
}

.data a {
    text-decoration: none;
    color: black;
}