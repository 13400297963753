.dashboard {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 0.5fr 0.5fr;
    width: 100vw;
    max-width: 400px;
    margin: 0 auto;

}

.dashboard-content {
    grid-row-start: 2;
    text-align: center;
    width: 148px;
    height: 148px;
    margin: 0 auto;
}

.dashboard-navigation {
    grid-row-start: 4;
    margin: 0 auto;
    height: 50px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard-navigation button {
    width: 50px;
    border: none;
    background: none;
}

.dashboard-edit {
    grid-row-start: 5;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-edit button {
    width: 50%;
    padding: 10px;
    margin-bottom: 40px;
    color: white;
    background-color: black;
    border-radius: 25px;
}

.icon {
    width: 50px;
    height: 50px;
}