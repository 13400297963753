.navbar {
    width: 100vw;
    max-width: 400px;
    margin: 0 auto;
    min-height: 90px;
    background-color: #F2F2F2;
    padding: 20px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-sizing: border-box;
}

.navbar-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    max-width: 400px;
    border-radius: 5px;
    padding: 10px 5px 10px 5px;
}

.navbar-items .icon {
    height: 30px;
    width: 30px;
}

.navbar-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    list-style-type: none;
}

.navbar-list li {
    margin: 10px 0 10px 0;
}