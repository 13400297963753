html,
body,
button,
input {
    font-family: Poppins, sans-serif;
}

html {
    height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

body {
    min-height: -webkit-fill-available;
    width: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    width: 100%;
}